import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ActivityMap, RankedColumnType, SampleResultsColumn } from 'state-domains/domain';

import { buildAddSampleResultsRankedColumn } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const addSampleResultsRankedColumn = (
    activityId: string,
    payload: RankedColumnType,
): Observable<{
    activity: ActivityMap;
    candidateColumns: SampleResultsColumn;
    rankedColumn: RankedColumnType;
}> => {
    const url = buildAddSampleResultsRankedColumn(activityId);
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(
            ({
                response,
            }: AjaxResponse<{
                activity: ActivityMap;
                candidateColumns: SampleResultsColumn;
                rankedColumn: RankedColumnType;
            }>) =>
                observableOf(
                    convertToCamel<{
                        activity: ActivityMap;
                        candidateColumns: SampleResultsColumn;
                        rankedColumn: RankedColumnType;
                    }>(response),
                ),
        ),
    );
};
